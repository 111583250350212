import React, { useMemo, useRef } from "react";
import { useRouter } from "next/router";
import Slider, { Settings } from "react-slick";
import Button from "@Components/button";
import useResponsive, { EScreenSize } from "@Hook/useResponsive";
import styles from "./CalculateCosts.module.scss";

const MENUS = [
   {
      title: "Căn hộ",
      icon: "/assets/img/calculate-costs/can_ho.png",
      iconLg: "/assets/img/calculate-costs/can_ho.png",
      icon1: "/assets/img/calculate-costs/calculation.png",
      iconLg1: "/assets/img/calculate-costs/calculation.png",
      description: "Ước tính chi phí thiết kế cho căn hộ của bạn",
      link: "/bao-gia/can-ho",
   },
   {
      title: "Nhà ở",
      icon: "/assets/img/calculate-costs/nha_o.png",
      iconLg: "/assets/img/calculate-costs/nha_o.png",
      icon1: "/assets/img/calculate-costs/calculation.png",
      iconLg1: "/assets/img/calculate-costs/calculation.png",
      description: "Ước tính chi phí thiết kế cho nhà ở của bạn",
      link: "/bao-gia/nha-o",
   },
   {
      title: "Bếp",
      icon: "/assets/img/calculate-costs/nha_bep.png",
      iconLg: "/assets/img/calculate-costs/nha_bep.png",
      icon1: "/assets/img/calculate-costs/calculation.png",
      iconLg1: "/assets/img/calculate-costs/calculation.png",
      description: "Ước tính chi phí thiết kế cho căn bếp của bạn",
      link: "/bao-gia/bep",
   },
   {
      title: "Tủ quần áo",
      icon: "/assets/img/calculate-costs/tu_quan_ao.png",
      iconLg: "/assets/img/calculate-costs/tu_quan_ao.png",
      icon1: "/assets/img/calculate-costs/calculation.png",
      iconLg1: "/assets/img/calculate-costs/calculation.png",
      description: "Ước tính chi phí thiết kế cho tủ quần áo của bạn",
      link: "/bao-gia/tu-quan-ao",
   },
];

const CalculateCosts = () => {
   const router = useRouter();
   const sliderRef = useRef<Slider>(null);
   const screenSize = useResponsive();
   const isMobile = screenSize === EScreenSize.MOBILE;

   const carouselSettings: Settings = useMemo(
      () => ({
         dots: false,
         arrows: false,
         speed: 500,
         slidesToShow: 4,
         slidesToScroll: 4,
         infinite: false,
         responsive: [
            {
               breakpoint: 768,
               settings: {
                  slidesToShow: 1.5,
                  slidesToScroll: 1,
               },
            },
            {
               breakpoint: 576,
               settings: {
                  slidesToShow: 1.5,
                  slidesToScroll: 1,
               },
            },
         ],
      }),
      []
   );

   const renderItemMobile = (item, index) => {
      return (
         <div className={styles["slider-item"]} key={index}>
            <div className={styles["content"]}>
               <div className={styles["item"]}>
                  <div className={styles["image-container"]}>
                     <img
                        title={item.title || ""}
                        alt={item.title || ""}
                        src={item.iconLg || ""}
                        style={{
                           height: "80px",
                           width: "104px",
                           borderRadius: "8px 0px 0px 0px",
                        }}
                     />
                     <img
                        title={item.title || ""}
                        alt={item.title || ""}
                        src={item.iconLg1 || ""}
                        style={{
                           top: 10,
                           right: 10,
                           height: "60px",
                           position: "relative",
                        }}
                     />
                  </div>
                  <div className={styles["title1"]}>{item.title}</div>
                  <div className={styles["title2"]}>{item.description}</div>
                  <div className={styles["button_wrapper"]}>
                     <Button
                        className="rounded"
                        width="100%"
                        type="submit"
                        variant="bt-outline-primary2"
                        size={true ? "medium" : "large"}
                        onClick={() => {
                           router.push(item.link);
                        }}
                     >
                        Nhận báo giá miễn phí
                     </Button>
                  </div>
               </div>
            </div>
         </div>
      );
   };

   const renderItemDesktop = (item, index) => {
      return (
         <div className="col-md-3" key={index}>
            <div className={styles["content"]}>
               <div className={styles["item"]}>
                  <div className={styles["image-container"]}>
                     <img
                        title={item.title || ""}
                        alt={item.title || ""}
                        src={item.iconLg || ""}
                        style={{
                           height: "80px",
                           width: "104px",
                           borderRadius: "8px 0px 0px 0px",
                        }}
                     />
                     <img
                        title={item.title || ""}
                        alt={item.title || ""}
                        src={item.iconLg1 || ""}
                        style={{
                           top: 10,
                           right: 10,
                           height: "60px",
                           position: "relative",
                        }}
                     />
                  </div>
                  <div className={styles["title1"]}>{item.title}</div>
                  <div className={styles["title2"]}>{item.description}</div>
                  <div className={styles["button_wrapper"]}>
                     <Button
                        className="rounded"
                        width="100%"
                        type="submit"
                        variant="bt-outline-primary2"
                        size={true ? "medium" : "large"}
                        onClick={() => {
                           router.push(item.link);
                        }}
                     >
                        Nhận báo giá miễn phí
                     </Button>
                  </div>
               </div>
            </div>
         </div>
      );
   };

   return (
      <div className={styles["block"]}>
         <div className={styles["block-sub"]}>
            <h2 className={styles["main_title"]}>Tính chi phí</h2>
         </div>
         <div className={styles["spacet-partner"]}>
            <div className="row gx-2 gy-2 gy-md-3 gx-md-3">
               {!isMobile ? (
                  MENUS.map(renderItemDesktop)
               ) : (
                  <Slider {...carouselSettings} ref={sliderRef}>
                     {MENUS.map(renderItemMobile)}
                  </Slider>
               )}
            </div>
         </div>
      </div>
   );
};

export default CalculateCosts;
