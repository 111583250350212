import React, { useRef, useState, useMemo } from "react";
import Link from "next/link";
import Slider, { Settings } from "react-slick";
import { useRouter } from "next/router";
import classnames from "classnames";
import { isMobile } from "react-device-detect";
import { truncateText } from "@Utils/index";
import SkeletonLoader from "@Components/skeleton-loader";
import Loadingv2 from "@Components/Loading/v2";
import { ImageView } from "@Components/image";
import { ModelError } from "@Models/model";
import GeneralMediasBySpace, { EGeneralMediasBySpaceSortBy } from "@Models/generalMediasBySpace.model";
import { TListRoom } from "@Models/keyword.model";
import styles from "./HinhAnhNoiThatContent.module.scss";

// *** data
interface IDataInfo {
   container: string;
   domain: string;
   originalName: string;
   protocol: string;
   size: number;
   fileType: string;
   extension: string;
   position: number;
   isAvatar: boolean;
   viewCount: number;
}
interface IDataOptions {
   status: string;
   storageProvider: string;
   isMigrated: boolean;
}
interface IDataMeta {
   altText?: any;
   description?: any;
   title?: any;
}
interface IDataInfo2 {
   content: string;
   slug: string;
}
interface IDataOptions2 {
   status: string;
   type: string;
}
interface IDataRelatedInfo {
   imageUrl?: string;
}
interface IDataKeyword {
   info: IDataInfo2;
   options: IDataOptions2;
   relatedInfo: IDataRelatedInfo;
   _id: string;
}
interface IDataInfo3 {
   title: string;
   slug: string;
   acreage: number;
   price: number;
   typeOfConstruction: string;
}
interface IDataOptions3 {
   status: string;
}
interface IDataPost {
   info: IDataInfo3;
   options: IDataOptions3;
   createdAt: string;
}
export interface IData {
   $createdAt_DDMMYYYY: string;
   _id: string;
   refDocId: string;
   createdAt: string;
   sortPoint: number;
   totalCount: number;
   __bookmarksCount: number;
   info: IDataInfo;
   options: IDataOptions;
   meta: IDataMeta;
   refDocModel: string;
   keywordIds: string[];
   __keywords: IDataKeyword[];
   __post: IDataPost;
   communityPostId: string;
   url: string;
   $url: string;
   $url64ResizeMax: string;
   $url5And6ColResizeMax: string;
   $url3And4ColResizeMax: string;
   $url2ColResizeMax: string;
   $url1ColResizeMax: string;
}
// * finish data

interface IProps {
   categories?: TListRoom;
   dataBySlug?: {
      docs?: IData[];
   };
}

const HinhAnhNoiThatContent: React.FC<IProps> = ({ categories, dataBySlug }) => {
   const sliderRef = useRef<Slider>(null);
   const router = useRouter();
   const [homeGalleries, setHomeGalleries] = useState<IData[]>(dataBySlug?.docs || []);
   const [slug, setSlug] = useState<string>(categories?.space?.[0]?.info?.slug ?? "");
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const isClient = useMemo(() => typeof window !== "undefined", []);

   const fetchHomeGalleries = async (slug: string) => {
      setIsLoading(true);

      try {
         const res = await GeneralMediasBySpace.paginatev2({
            page: 1,
            limit: 4,
            url: slug,
            sort: "desc",
            sortBy: EGeneralMediasBySpaceSortBy.viewCount,
         });

         if (res instanceof ModelError) {
            throw new Error("Failed to fetch galleries");
         }

         const payload = res?.docs?.map((item) => new GeneralMediasBySpace(item)) || [];

         setHomeGalleries(payload as any);
      } catch (error) {
      } finally {
         setIsLoading(false);
      }
   };

   const carouselSettings: Settings = useMemo(
      () => ({
         dots: false,
         arrows: false,
         speed: 500,
         slidesToShow: 4,
         slidesToScroll: 4,
         infinite: false,
         responsive: [
            {
               breakpoint: 768,
               settings: {
                  slidesToShow: 3.5,
                  slidesToScroll: 3,
               },
            },
            {
               breakpoint: 576,
               settings: {
                  slidesToShow: 2.5,
                  slidesToScroll: 2,
               },
            },
         ],
      }),
      []
   );

   const handleFilterClick = (newSlug: string) => {
      sliderRef.current?.slickGoTo(0);
      setSlug(newSlug);
      fetchHomeGalleries(newSlug);
   };

   const renderTitle = (item: IData) => {
      if (item?.__keywords?.find((keyword) => keyword?.options?.type === "space")) {
         const listRoom: any = [];
         item?.__keywords?.map((keyword) => {
            if (keyword?.options?.type === "space") {
               listRoom.push(keyword?.info?.content || "");
            }
         });
         return `${listRoom?.map((i: string, index: number) => `${index > 0 ? " " : ""}${i}`)} - ${
            item?.__post?.info?.title || ""
         } `;
      } else {
         return `${item?.__post?.info?.title || ""}`;
      }
   };

   const renderSliderItems = () => {
      return homeGalleries?.map((item: IData) => {
         const title = renderTitle(item);
         return (
            <div key={item._id} className={styles["slider-item"]}>
               <Link
                  style={router.query.isApp === "true" ? { pointerEvents: "none" } : {}}
                  title={title}
                  href={`/thu-vien-anh/${item.url}`}
                  className="text-decoration-none"
               >
                  <div className={styles["img"]}>
                     <ImageView
                        objectFit="cover"
                        src={item?.$url3And4ColResizeMax}
                        alt="image-by-space"
                        className={styles["type-space-item_image"]}
                     />
                  </div>
                  <div className={styles["article-item-info"]}>
                     <div className={styles["truncate-line-2"]}>{truncateText(title, 80)}</div>
                  </div>
               </Link>
            </div>
         );
      });
   };

   const style = useMemo(() => {
      const isEmptyOrLoading = homeGalleries.length === 0 || isLoading;
      return {
         display: isEmptyOrLoading ? "flex" : "block",
         justifyContent: isEmptyOrLoading ? "center" : "unset",
         alignItems: isEmptyOrLoading ? "center" : "unset",
         height: isMobile ? "158px" : "256px",
      };
   }, [homeGalleries.length, isLoading, isMobile]);

   const renderContent = () => {
      if (isClient) {
         if (isLoading) {
            return <Loadingv2 />;
         } else if (homeGalleries.length === 0) {
            return <SkeletonLoader />;
         }
      }

      return (
         <Slider {...carouselSettings} ref={sliderRef}>
            {renderSliderItems()}
         </Slider>
      );
   };

   return (
      <section className={styles["construction-design"]}>
         <div className={styles["filter-cate"]}>
            {categories?.space?.map((item) => (
               <div
                  key={item._id}
                  className={classnames(styles["filter-item"], {
                     [styles["active"]]: slug === item?.info?.slug,
                  })}
                  onClick={() => handleFilterClick(item?.info?.slug)}
               >
                  {item?.info?.content}
               </div>
            ))}
         </div>
         <div style={style}>{renderContent()}</div>
      </section>
   );
};

export default HinhAnhNoiThatContent;
