import React from "react";
import useResponsive, { EScreenSize } from "@Hook/useResponsive";
import styles from "./BenefitWithSpaceT.module.scss";

const MENUS = [
   {
      title: "Không chi phí ẩn",
      icon: "/assets/img/ko_chi_phi_an.png",
      iconLg: "/assets/img/ko_chi_phi_an.png",
      path: "",
   },
   {
      title: "Bảo hành 1+ năm",
      icon: "/assets/img/bh_1_nam.png",
      iconLg: "/assets/img/bh_1_nam.png",
      path: "",
   },

   {
      title: "1000+ dự án đã hoàn thiện",
      icon: "/assets/img/du_an_hoan_thien.png",
      iconLg: "/assets/img/du_an_hoan_thien.png",
      path: "",
   },
];

const BenefitWithSpaceT = () => {
   const screenSize = useResponsive();
   const isMobile = screenSize === EScreenSize.MOBILE;

   return (
      <div className={styles["block"]}>
         <div className={styles["block-sub"]}>
            <h2 className={styles["main_title"]}>Lợi ích khi kết nối với Space T</h2>
         </div>
         <div className={styles["spacet-partner"]}>
            <div className="row gx-2 gy-2 gy-md-3 gx-md-3">
               {MENUS.map((item, index) => (
                  <div className="col-4" key={index}>
                     <div className={styles["content"]}>
                        <div className={styles["item"]}>
                           <img
                              title={item.title || ""}
                              alt={item.title || ""}
                              src={item.iconLg || ""}
                              width={!isMobile ? "96px" : "48px"}
                              height={!isMobile ? "96px" : "48px"}
                           />
                           <div className={styles["title"]}>{item.title}</div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};

export default BenefitWithSpaceT;
